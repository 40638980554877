import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { colors } from "@yoast/style-guide";
import { injectIntl, intlShape, FormattedDate, defineMessages, FormattedMessage } from "react-intl";
import Link from "../../Link";
import { RowMobileCollapse, ListTable, ColumnFixedWidth, ColumnMinWidth, makeFullWidth } from "../../Tables";
import defaults from "../../../config/defaults.json";
import { LinkButton, RedLinkButton } from "../../Button";
import { capitalizeFirstLetter } from "shared-frontend/functions/stringHelpers";
import { generateRenewalUrl } from "../../../functions/generateRenewalUrl";
import SubscriptionEditModal from "../../../containers/SubscriptionEditModal";
import AddSiteToSubscriptionModal from "../../../containers/AddSiteToSubscriptionModal";
import { UpgradeSubscriptionModal } from "../../modal/UpgradeSubscriptionModal";
import { subscriptionShape } from "./SubscriptionPropTypeShape";
import {
	generateSwitchPaymentMethodUrl,
	generateSwitchToAutomaticUrl,
} from "../../../functions/subscriptionActionLinks";

const messages = defineMessages( {
	paymentDetailsTitle: {
		id: "subscriptionDetails.paymentDetails.title",
		defaultMessage: "Payment details",
	},
	invoicesTitle: {
		id: "subscriptionDetails.invoices.title",
		defaultMessage: "Invoices",
	},
	status: {
		id: "subscriptionDetails.paymentDetails.status",
		defaultMessage: "Subscription status",
	},
	number: {
		id: "subscriptionDetails.paymentDetails.number",
		defaultMessage: "Subscription number",
	},
	startDate: {
		id: "subscriptionDetails.paymentDetails.start-date",
		defaultMessage: "Start date",
	},
	endDate: {
		id: "subscriptionDetails.paymentDetails.end-date",
		defaultMessage: "End date",
	},
	nextBilling: {
		id: "subscriptionDetails.paymentDetails.nextBilling",
		defaultMessage: "Next billing",
	},
	invoiceButton: {
		id: "subscriptionDetails.buttons.invoice",
		defaultMessage: "Invoice",
	},
	cancelLink: {
		id: "subscriptionDetails.buttons.cancel",
		defaultMessage: "Cancel subscription",
	},
	cancelPending: {
		id: "subscriptionDetails.cancelPending",
		defaultMessage: "Your subscription has been cancelled.",
	},
	extendSubscriptionLabel: {
		id: "subscriptionDetails.extendSubscriptionLabel",
		defaultMessage: "Renew subscription",
	},
	switchPayentMethodLabel: {
		id: "subscriptionDetails.extendSubscriptionLabel",
		defaultMessage: "Use another payment method for your next renewal",
	},
	extendSubscription: {
		id: "subscriptionDetails.extendSubscription",
		defaultMessage: "Renew subscription with a { billingTerm }",
	},
	switchToAutomaticLabel: {
		id: "subscriptionDetails.switchToAutomaticLabel",
		defaultMessage: "Automatic renewal",
	},
	switchToAutomatic: {
		id: "subscriptionDetails.switchToAutomatic",
		defaultMessage: "Switch to automatic billing (and get 10% off)",
	},
	switchPaymentMethod: {
		id: "subscriptionDetails.label.switchPaymentMethod",
		defaultMessage: "Switch payment method",
	},
	addSiteToSubscription: {
		id: "subscriptionDetails.label.addSite",
		defaultMessage: "Add site",
	},
	cannotAddSiteToSubscription: {
		id: "subscriptionDetails.cant.addSite",
		defaultMessage: "You cannot add a site to a subscription that lasts more than five years",
	},
	addSiteToSubscriptionLink: {
		id: "subscriptionDetails.buttons.addSiteLink",
		defaultMessage: "Add site to this subscription",
	},
	upgradeToBundleLabel: {
		id: "subscriptionDetails.label.upgradeToBundle",
		defaultMessage: "Upgrade to bundle",
	},
	upgradeToBundleLink: {
		id: "subscriptionDetails.link.upgradeToBundle",
		defaultMessage: "Upgrade subscription to bundle",
	},
} );

export const RowMobileCollapseNoMinHeight = styled( RowMobileCollapse )`
	@media screen and ( max-width: ${ defaults.css.breakpoint.tablet }px ) {
		min-height: 0;

		> span:nth-child(2) {
			margin-top: 0;
			color: ${ colors.$color_black };
		}

		> span:first-child {
			color: ${ colors.$color_grey_text };
		}
	}
`;

export const ColumnFixedWidthResponsive = makeFullWidth( ColumnFixedWidth );

/* eslint-disable react/jsx-no-bind */
/**
 * The SubscriptionDetails component.
 *
 * @param {Object} props The props to use
 *
 * @returns {JSX.Element} The rendered SubscriptionDetails component.
 *
 *
 */
function SubscriptionDetails( props ) {
	const [ addSiteModalOpen, setAddSiteModalOpen ]                 = useState( false );
	const [ upgradeToBundleModalOpen, setUpgradeToBundleModalOpen ] = useState( false );
	const [ cancelModalOpen, setCancelModalOpen ]                   = useState( false );

	let nextBilling = "-";
	let endDate     = "-";
	if ( props.hasNextBilling || props.hasEndDate ) {
		// Use end date for EDD subscriptions which will be renewed in a new WooCommerce Subscription.
		nextBilling = <FormattedDate
			value={ props.hasNextBilling ? props.nextBilling : props.endDate }
			year="numeric"
			month="long"
			day="2-digit"
		/>;
	}

	if ( props.hasEndDate ) {
		endDate = <FormattedDate
			value={ props.endDate }
			year="numeric"
			month="long"
			day="2-digit"
		/>;
	}

	const extendSubscriptionLink = <Link to={ generateRenewalUrl( props.subscription ) }>
		<FormattedMessage
			{ ...messages.extendSubscription }
			values={ {
				billingTerm: props.subscription.product.billingTerm,
			} }
		/>
	</Link>;

	const switchPaymentMethodLink = <Link
		to={ generateSwitchPaymentMethodUrl( props.subscription ) }
		linkTarget="_blank"
	>
		<FormattedMessage
			{ ...messages.switchPaymentMethod }
			values={ {
				billingTerm: props.subscription.product.billingTerm,
			} }
		/>
	</Link>;
	const switchToAutomaticLink = <Link
		to={ generateSwitchToAutomaticUrl( props.subscription ) }
		linkTarget="_blank"
	>
		<FormattedMessage { ...messages.switchToAutomatic } />
	</Link>;

	// Shown when the cancellation of the subscription is pending.
	const pendingCancelMessage               = <FormattedMessage { ...messages.cancelPending } />;
	const cancelLinkMessage                  = <FormattedMessage { ...messages.cancelLink } />;
	const addSiteToSubscriptionMessage       = <FormattedMessage { ...messages.addSiteToSubscription } />;
	const addSiteToSubscriptionLinkMessage   = <FormattedMessage { ...messages.addSiteToSubscriptionLink } />;
	const cannotAddSiteToSubscriptionMessage = <FormattedMessage { ...messages.cannotAddSiteToSubscription } />;
	const upgradeToBundleLabelMessage        = <FormattedMessage { ...messages.upgradeToBundleLabel } />;
	const upgradeToBundleLinkMessage         = <FormattedMessage { ...messages.upgradeToBundleLink } />;

	// Shown when the subscription can be cancelled, but has not been cancelled yet.
	const cancelLink = (
		<RedLinkButton type="button" onClick={ () => setCancelModalOpen( true ) }>{ cancelLinkMessage }</RedLinkButton>
	);

	const addSiteToSubscription = (
		<LinkButton
			type="button"
			onClick={ () => setAddSiteModalOpen( true ) }
		>{ addSiteToSubscriptionLinkMessage }</LinkButton>
	);

	const upgradeToBundleLink = (
		<LinkButton
			className="upgrade_subscription_to_bundle_btn"
			type="button"
			onClick={ () => setUpgradeToBundleModalOpen( true ) }
		>{ upgradeToBundleLinkMessage }</LinkButton>
	);

	const today = new Date();
	today.setHours( 0, 0, 0, 0 );

	const nextBillingLessThanFiveYears = useMemo(
		() => props.nextBilling <= ( new Date( today.setFullYear( today.getFullYear() + 5 ) ).getTime() ),
		[ props.nextBilling ],
	);

	const statusRow = (
		<RowMobileCollapseNoMinHeight hasHeaderLabels={ false } key="status">
			<ColumnMinWidth ellipsis={ true }>
				{ props.intl.formatMessage( messages.status ) }
			</ColumnMinWidth>
			<ColumnFixedWidthResponsive ellipsis={ true }>
				{ capitalizeFirstLetter( props.subscription.status ) }
			</ColumnFixedWidthResponsive>
		</RowMobileCollapseNoMinHeight>
	);

	const numberRow = (
		<RowMobileCollapseNoMinHeight hasHeaderLabels={ false } key="number">
			<ColumnMinWidth ellipsis={ true }>
				{ props.intl.formatMessage( messages.number ) }
			</ColumnMinWidth>
			<ColumnFixedWidthResponsive ellipsis={ true }>
				{ props.subscription.subscriptionNumber }
			</ColumnFixedWidthResponsive>
		</RowMobileCollapseNoMinHeight>
	);

	const startingDateRow = (
		<RowMobileCollapseNoMinHeight hasHeaderLabels={ false } key="start-date">
			<ColumnMinWidth ellipsis={ true }>
				{ props.intl.formatMessage( messages.startDate ) }
			</ColumnMinWidth>
			<ColumnFixedWidthResponsive ellipsis={ true }>
				<FormattedDate
					value={ props.startDate }
					year="numeric"
					month="long"
					day="2-digit"
				/>
			</ColumnFixedWidthResponsive>
		</RowMobileCollapseNoMinHeight>
	);

	const nextBillingDateRow = (
		<RowMobileCollapseNoMinHeight hasHeaderLabels={ false } key="next-billing">
			<ColumnMinWidth ellipsis={ true }>
				{ props.intl.formatMessage( messages.nextBilling ) }
			</ColumnMinWidth>
			<ColumnFixedWidthResponsive ellipsis={ true }>
				{ nextBilling }
			</ColumnFixedWidthResponsive>
		</RowMobileCollapseNoMinHeight>
	);

	const endDateRow = (
		<RowMobileCollapseNoMinHeight hasHeaderLabels={ false } key="end-date">
			<ColumnMinWidth ellipsis={ true }>
				{ props.intl.formatMessage( messages.endDate ) }
			</ColumnMinWidth>
			<ColumnFixedWidthResponsive ellipsis={ true }>
				{ endDate }
			</ColumnFixedWidthResponsive>
		</RowMobileCollapseNoMinHeight>
	);

	const extendSubscriptionRow = (
		<RowMobileCollapseNoMinHeight hasHeaderLabels={ false } key="extend-subscription">
			<ColumnMinWidth ellipsis={ true }>
				{ props.intl.formatMessage( messages.extendSubscriptionLabel ) }
			</ColumnMinWidth>
			<ColumnFixedWidthResponsive ellipsis={ true }>
				{ extendSubscriptionLink }
			</ColumnFixedWidthResponsive>
		</RowMobileCollapseNoMinHeight>
	);

	const switchPaymentMethodRow = (
		<RowMobileCollapseNoMinHeight hasHeaderLabels={ false } key="switch-payment-method">
			<ColumnMinWidth ellipsis={ true }>
				{ props.intl.formatMessage( messages.switchPayentMethodLabel ) }
			</ColumnMinWidth>
			<ColumnFixedWidthResponsive ellipsis={ true }>
				{ switchPaymentMethodLink }
			</ColumnFixedWidthResponsive>
		</RowMobileCollapseNoMinHeight>
	);

	const switchToAutomaticRow = (
		<RowMobileCollapseNoMinHeight hasHeaderLabels={ false } key="switch-to-automatic">
			<ColumnMinWidth ellipsis={ true }>
				{ props.intl.formatMessage( messages.switchToAutomaticLabel ) }
			</ColumnMinWidth>
			<ColumnFixedWidthResponsive ellipsis={ true }>
				{ switchToAutomaticLink }
			</ColumnFixedWidthResponsive>
		</RowMobileCollapseNoMinHeight>
	);

	const cancelSubscriptionRow = (
		<RowMobileCollapseNoMinHeight hasHeaderLabels={ false } key="cancel">
			<ColumnMinWidth ellipsis={ true }>
				{ props.subscription.status === "pending-cancel" ? pendingCancelMessage : cancelLinkMessage }
			</ColumnMinWidth>
			<ColumnFixedWidthResponsive ellipsis={ true }>
				{ props.subscription.status === "pending-cancel" ? pendingCancelMessage : cancelLink }
			</ColumnFixedWidthResponsive>
		</RowMobileCollapseNoMinHeight> );

	const addSiteToSubscriptionRow = (
		<RowMobileCollapseNoMinHeight hasHeaderLabels={ false } key="addSite">
			<ColumnMinWidth ellipsis={ true }>
				{ addSiteToSubscriptionMessage }
			</ColumnMinWidth>
			<ColumnFixedWidthResponsive ellipsis={ true }>
				{ nextBillingLessThanFiveYears ? addSiteToSubscription : cannotAddSiteToSubscriptionMessage }
			</ColumnFixedWidthResponsive>
		</RowMobileCollapseNoMinHeight> );


	const upgradeToBundleRow = (
		<RowMobileCollapseNoMinHeight hasHeaderLabels={ false } key="upgradeToBundle">
			<ColumnMinWidth ellipsis={ true }>
				{ upgradeToBundleLabelMessage }
			</ColumnMinWidth>
			<ColumnFixedWidthResponsive ellipsis={ true }>
				{ upgradeToBundleLink }
			</ColumnFixedWidthResponsive>
		</RowMobileCollapseNoMinHeight> );

	const rows = [ statusRow, numberRow, startingDateRow ];
	if ( props.subscription.status === "active" || props.subscription.status === "on-hold" ) {
		rows.push( nextBillingDateRow );
	} else {
		rows.push( endDateRow );
	}

	if ( props.subscription.requiresManualRenewal === true && props.subscription.status === "active" && ! props.subscription.provisionerId ) {
		rows.push( switchToAutomaticRow );
	}

	if ( [ "active", "on-hold", "expired" ].includes( props.subscription.status ) ) {
		rows.push( extendSubscriptionRow );
	}

	if ( props.subscription.status !== "pending-cancel" && props.subscription.product.glNumber !== "82109" ) {
		rows.push( addSiteToSubscriptionRow );
	}

	const hasActiveSiblings = props.connectedSubscriptions.some(
		subscription => ! [ "expired", "cancelled", "refunded", "pending-cancel" ].includes( subscription.status ),
	);
	if ( props.subscription.status === "active" && ! hasActiveSiblings ) {
		rows.push( upgradeToBundleRow );
	}

	if ( ! props.subscription.requiresManualRenewal && props.subscription.status === "active" && ! props.subscription.provisionerId ) {
		rows.push( switchPaymentMethodRow );
	}

	rows.push( cancelSubscriptionRow );

	return (
		<>
			<ListTable>
				{ rows }
			</ListTable>
			<SubscriptionEditModal
				subscriptionId={ props.subscription.id }
				isOpen={ cancelModalOpen }
				onClose={ () => setCancelModalOpen( false ) }
			/>
			<AddSiteToSubscriptionModal
				subscriptionId={ props.subscription.id }
				isOpen={ addSiteModalOpen }
				onClose={ () => setAddSiteModalOpen( false ) }
			/>
			<UpgradeSubscriptionModal
				subscriptionId={ props.subscription.id }
				isOpen={ upgradeToBundleModalOpen }
				onClose={ () => setUpgradeToBundleModalOpen( false ) }
			/>
		</>
	);
}

/* eslint-enable */

SubscriptionDetails.propTypes = {
	startDate: PropTypes.instanceOf( Date ).isRequired,
	hasNextBilling: PropTypes.bool.isRequired,
	nextBilling: PropTypes.instanceOf( Date ).isRequired,
	hasEndDate: PropTypes.bool.isRequired,
	endDate: PropTypes.instanceOf( Date ).isRequired,
	orders: PropTypes.array.isRequired,
	max: PropTypes.number.isRequired,
	current: PropTypes.number.isRequired,
	intl: intlShape.isRequired,
	subscription: subscriptionShape.isRequired,
	connectedSubscriptions: PropTypes.arrayOf( subscriptionShape ).isRequired,
};

export default injectIntl( SubscriptionDetails );
